import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const GifPlayer = makeShortcode("GifPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLinks small mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Server Deployments</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Station Deployments</AnchorLink>
  </AnchorLinks>
  <AnchorLink mdxType="AnchorLink">Bug Fixes</AnchorLink>
    </AnchorLinks>
    <InlineNotification mdxType="InlineNotification">
  To see images/gifs/vidoes below, please connect to VPN
    </InlineNotification>
    <h2>{`Features`}</h2>
    <h3>{`Server Deployments`}</h3>
    <ul>
      <li parentName="ul">{`Agent download button in Deployemnt Details view when the new deployment is created`}</li>
    </ul>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.6.10%252Fagent-download-button.gif&isNativeBrowsing=true",
        "alt": "Server agent download button"
      }}></img>
      <img {...{
        "src": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.6.10%252Fagent-download-button.png&isNativeBrowsing=true",
        "alt": "Server agent download button"
      }}></img>
    </GifPlayer>
    <ul>
      <li parentName="ul">{`Workflow step sub-steps autorefresh and autoscroll`}</li>
    </ul>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.6.10%252Fsub-steps-auto-refresh.gif&isNativeBrowsing=true",
        "alt": "Sub-steps autorefresh"
      }}></img>
      <img {...{
        "src": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.6.10%252Fsub-steps-auto-refresh.png&isNativeBrowsing=true",
        "alt": "Sub-steps autorefresh"
      }}></img>
    </GifPlayer>
    <ul>
      <li parentName="ul">{`Full (non-truncated) sub-step output (requires corresponding automation configuration update)`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Added Date Created column to the deployments grid, new deployments are now ordered by it`}</li>
    </ul>
    <h3>{`Station Deployments`}</h3>
    <ul>
      <li parentName="ul">{`Station Upgrade executions are now ordered by the start date of the first wave`}</li>
    </ul>
    <h4>{`Wave Planning`}</h4>
    <ul>
      <li parentName="ul">{`‘Unassigned’ station grid now becomes ‘All Stations’, which allows to search through the entire device fleet in one place`}</li>
    </ul>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.6.10%252Fall-stations-search.gif&isNativeBrowsing=true",
        "alt": "Sub-steps autorefresh"
      }}></img>
      <img {...{
        "src": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.6.10%252Fall-stations-search.png&isNativeBrowsing=true",
        "alt": "Sub-steps autorefresh"
      }}></img>
    </GifPlayer>
    <ul>
      <li parentName="ul">{`More that one device at a time can be assigned to a wave`}</li>
    </ul>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.6.10%252Fstation-bulk-assignment.gif&isNativeBrowsing=true",
        "alt": "Sub-steps autorefresh"
      }}></img>
      <img {...{
        "src": "https://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.6.10%252Fstation-bulk-assignment.png&isNativeBrowsing=true",
        "alt": "Sub-steps autorefresh"
      }}></img>
    </GifPlayer>
    <ul>
      <li parentName="ul">{`Device Name is now displayed next to the Hostname, which allows to search stations by it`}</li>
    </ul>
    <h4>{`Wave Execution`}</h4>
    <ul>
      <li parentName="ul">{`If the station image base URL is not set (for example, when images were never downloaded to the app server or AIO), a warning is displayed`}</li>
    </ul>
    <h2>{`Bug Fixes`}</h2>
    <ul>
      <li parentName="ul">{`In Wave Planning, deleting a disconnected device should not cause any errors`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      